/* eslint-disable multiline-ternary */
/* eslint-disable tailwindcss/no-custom-classname */
'use client'
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  ControlProps,
  OptionProps,
  MultiValueRemoveProps,
  MultiValueGenericProps,
} from 'react-select'
import Creatable from 'react-select/creatable'

import styles from './Select.module.css'
import { TSelectOption, TSelectProps } from './Select.type'
import { ChevronDown, X } from 'react-feather'
import Checkbox from '../Checkbox/Checkbox'
import { cx } from 'class-variance-authority'

function DropdownIndicator(props: DropdownIndicatorProps<TSelectOption>) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown size={16} />
    </components.DropdownIndicator>
  )
}

function MultiValueRemove(dtProps: MultiValueRemoveProps<TSelectOption>) {
  return (
    <components.MultiValueRemove {...dtProps}>
      <X size={12} className={styles.multiValueRemove} />
    </components.MultiValueRemove>
  )
}

function MultiValueContainer(props: MultiValueGenericProps<TSelectOption>) {
  return (
    <components.MultiValueContainer {...props}>
      <div className={styles.multiValuePills}>{props.children}</div>
    </components.MultiValueContainer>
  )
}

function Control({ children, ...props }: ControlProps<TSelectOption>) {
  const { iconLeft: IconLeft } = props.selectProps as TSelectProps

  return (
    <components.Control {...props}>
      {IconLeft && (
        <span className={styles.iconLeft}>
          <IconLeft size={20} />
        </span>
      )}
      {children}
    </components.Control>
  )
}

function Option(props: OptionProps<TSelectOption>) {
  const { children, ...rest } = props
  const Icon = props.data.icon
  return (
    <components.Option {...rest}>
      {Icon && (
        <span className="select__option-icon">
          <Icon size={20} />
        </span>
      )}
      <div className="select__option-wrapper">
        <span className="select__option-label">{children}</span>
        <span className="select__option-description">{props.data.description}</span>
      </div>
    </components.Option>
  )
}

function OptionWithCheckbox(props: OptionProps<TSelectOption>) {
  const { children, ...rest } = props
  const Icon = props.data.icon
  return (
    <components.Option {...rest}>
      <Checkbox className="select__option-checkbox" checked={props.isSelected} disabled={props.isDisabled} readOnly />
      {Icon && (
        <span className="select__option-icon">
          <Icon size={20} />
        </span>
      )}
      <div className="select__option-wrapper">
        <span className="select__option-label">{children}</span>
        <span className="select__option-description">{props.data.description}</span>
      </div>
    </components.Option>
  )
}
export default function Select(props: TSelectProps) {
  const {
    label,
    caption,
    variant,
    iconLeft: IconLeft,
    disabled = false,
    isCreatable,
    isMultiWithCheckbox,
    showDropdownIndicator = true,
    captionClassName,
    captionIcon,
    labelClassName,
    labelRef,
    ...rest
  } = props
  return (
    <label data-variant={variant} ref={labelRef} data-has-icon-left={Boolean(IconLeft)} className={labelClassName}>
      {label && <div className={styles.label}>{label}</div>}
      {!isCreatable ? (
        <ReactSelect
          {...rest}
          isDisabled={disabled}
          className={styles.select}
          classNamePrefix="select"
          unstyled
          components={{
            DropdownIndicator: showDropdownIndicator ? DropdownIndicator : null,
            Control,
            Option: isMultiWithCheckbox ? OptionWithCheckbox : Option,
            ...rest.components,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconLeft={IconLeft}
        />
      ) : (
        <Creatable
          {...rest}
          isDisabled={disabled}
          className={styles.select}
          classNamePrefix="select"
          unstyled
          components={{
            DropdownIndicator,
            Control,
            Option,
            MultiValueRemove,
            MultiValueContainer,
            IndicatorsContainer: () => null,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconLeft={IconLeft}
        />
      )}
      {caption && (
        <div className={cx(Boolean(captionIcon) && 'flex flex-nowrap items-center')}>
          {!!captionIcon && <div className="mr-1 mt-2 h-3 w-3 shrink-0">{captionIcon}</div>}
          <div className={cx(styles.caption, captionClassName)}>{caption}</div>
        </div>
      )}
    </label>
  )
}
