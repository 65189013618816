export default function formatBytes(
  bytes: number,
  decimals: number = 2
): string {
  if (bytes === 0) return "0 Bytes"

  const k = 1000
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
}
