import { NO_TAXABLE_WORDING } from "shared-utils"

const generateTaxLabel = ({
  ppnPercentage,
  ppnBmPercentage,
}: {
  ppnPercentage?: number
  ppnBmPercentage?: number
}) => {
  if (ppnPercentage && ppnBmPercentage)
    return `Termasuk PPN ${ppnPercentage}% & PPnBM ${ppnBmPercentage}%`
  if (ppnPercentage) return `Termasuk PPN ${ppnPercentage}%`
  if (ppnBmPercentage) return `Termasuk PPnBM ${ppnBmPercentage}%`
  return NO_TAXABLE_WORDING
}

export default generateTaxLabel