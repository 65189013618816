import { TChipsProps } from './Chips.type'
import styles from './Chips.module.css'
import { cx } from 'class-variance-authority'

function Chips(props: TChipsProps) {
  const {
    color = 'primary',
    size = 'medium',
    variant = 'normal',
    iconLeft: IconLeft,
    iconRight: IconRight,
    className,
    children,
    onClick,
    ...rest
  } = props
  return (
    <span
      {...rest}
      className={cx(styles.chips, className)}
      data-size={size}
      data-color={color}
      data-variant={variant}
      data-only-icon={Boolean(!children && (IconLeft || IconRight))}
      data-clickable={onClick !== undefined}
      onClick={onClick}
    >
      {IconLeft && <IconLeft className={styles.iconLeft} size={12} />}
      {children}
      {IconRight && <IconRight className={styles.iconRight} size={12} />}
    </span>
  )
}

export default Chips
