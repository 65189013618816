export const inputStyle = `
  outline-none
  border-none
  p-0
  flex-1
  text-caption-lg-regular
  bg-transparent
  placeholder-tertiary200
  disabled:pointer-events-none
  w-full
  [&:hover+div]:border-tertiary200

  [&:focus+div]:border-tertiary200
  [&:focus+div]:shadow-0

  [&:disabled+div]:border-tertiary200
`

export const baseIconStyle = `
  w-5
  h-auto
  max-h-5
  text-tertiary500
`

export const wrapperStyle = {
  base: `
    relative
    inline-flex
    items-center
    w-full
    px-4
    box-border
    text-tertiary500
    cursor-text
    block
  `,
  inline: `
    bg-tertiary25
  `,
}

export const wrapperStyleSize = {
  small: 'h-8',
  medium: 'h-10',
  large: 'h-12',
}

export const addonLeftAddonRightStyle = {
  base: `
    text-caption-sm-semibold
    inline-flex
    justify-center
    items-center
    px-2
    text-tertiary300 
  `,
  outline: `
    h-full
    bg-tertiary50
  `,
  inline: `
    my-2
    border-0
    border-solid
    border-tertiary50
  `,
}

export const addonLeftStyle = {
  base: `
    mr-4
  `,
  outline: `
    rounded-l-4
  `,
  inline: `
    rounded-l-2 border-r
  `,
}

export const addonRightStyle = {
  base: `
    ml-4
  `,
  outline: `
    rounded-r-4
  `,
  inline: `
    rounded-r-2 border-l
  `,
}

export const labelStyle = `
  text-caption-lg-semibold
  text-left
  block
  break-words
  mb-2
  text-tertiary500
`

export const captionStyle = `
  text-caption-sm-regular
  text-left
  block
  break-words
  mt-2
`

export const borderModifierStyle = {
  base: `
    absolute
    h-full
    w-full
    top-0
    left-0
    box-border
    pointer-events-none
  `,
  outline: `
    rounded-4
    border
    border-solid
    border-tertiary50
  `,
  outlineNoBorderRadius: `
    border
    border-solid
    border-tertiary50
  `,
  inline: `
    rounded-2
    border-t-0
    border-l-0
    border-r-0
    border-b-2
    border-solid
    border-tertiary200
  `,
  noBorder: `
    border-0
  `,
}
