import { cx } from 'class-variance-authority'
import { useState, type RefObject, useEffect } from 'react'

interface TextInputCounterProps {
  inputRef: RefObject<HTMLInputElement | undefined> | RefObject<HTMLTextAreaElement | undefined>
  maxLength: number
  variant: 'error' | 'normal' | 'success'
  initialLength: number
  isInputDisabled?: boolean
}

// We create a separate component to listen to textfield events and re-render the character count
// to prevent the whole textfield component from re-renders everytime user types something
export default function TextInputCounter({
  initialLength,
  inputRef,
  maxLength,
  variant,
  isInputDisabled,
}: TextInputCounterProps) {
  const [count, setCount] = useState(initialLength)

  useEffect(() => {
    function handleValueChange() {
      setCount(inputRef.current?.value.length ?? 0)
    }

    inputRef.current?.addEventListener('input', handleValueChange)

    return () => inputRef.current?.removeEventListener('input', handleValueChange)
  }, [inputRef.current])

  useEffect(() => {
    if (isInputDisabled) {
      setCount(initialLength)
    }
  }, [initialLength])

  return (
    <div className={cx('mt-2 text-xs font-normal', variant === 'error' ? 'text-error500' : 'text-tertiary300')}>
      {count}/{maxLength}
    </div>
  )
}
