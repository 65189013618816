export function isSubpath(basePath: string, path: string | null): boolean {
  const basePathSegments = splitSegments(basePath)
  const pathSegments = splitSegments(path || '')

  if ((basePath === '/' && path !== '/') || basePath.includes('#')) return false

  if (basePathSegments.length > pathSegments.length) {
    return false
  }

  for (let i = 0; i < basePathSegments.length; i++) {
    if (pathSegments[i] !== basePathSegments[i]) {
      return false
    }
  }

  return true
}

function splitSegments(path: string) {
  return new URL(path, 'dummy:/').pathname.replace(/\/$/, '').split('/')
}
