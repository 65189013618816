import { cx } from 'class-variance-authority'

import styles from './ProgressBar.module.css'
import { TProgressBarProps } from './ProgressBar.type'

interface numberIndicatorStyleProps {
  left?: string
  opacity?: number
}

function ProgressBar(props: TProgressBarProps) {
  const { value = 0, numberIndicatorStyle = 'hidden', className, color, ...rest } = props

  const validatedValue = value > 100 ? 100 : value < 0 ? 0 : value
  const numberIndicator: numberIndicatorStyleProps = {}
  if (numberIndicatorStyle === 'float-top' || numberIndicatorStyle === 'float-bottom') {
    numberIndicator.left = `${validatedValue}%`
  }

  return (
    <span className={cx(styles.container, className)} {...rest}>
      <div className={styles.bar}>
        <div
          className={styles.progress}
          style={{
            width: `${validatedValue}%`,
            backgroundColor: color,
          }}
        />
      </div>
      <span className={styles.number} data-type={numberIndicatorStyle} style={numberIndicator}>
        <p className={styles.numberIndicator}>{validatedValue}%</p>
      </span>
    </span>
  )
}

export default ProgressBar
