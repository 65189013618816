'use client'

import { calculatePriceAfterTax, capitalize, formatIntoCurrency } from 'shared-utils'
import { Chips, FieldValue } from '../../index'

import { cx } from 'class-variance-authority'
import { useState } from 'react'
import { TNegoItemInformationProps, TShippingSectionProps } from './NegoItemInformation.types'
import generateLabelTax from './helpers/generateTaxLabel'

const fieldClassNames = {
  label: 'w-2/5',
  value: 'w-3/5',
}

const productFieldClassName = {
  container: 'text-caption-lg-regular',
  label: 'text-caption-lg-regular',
  value: 'text-caption-lg-bold',
}

const ShippingSection = ({ shippingInfo }: TShippingSectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const isShowMoreButton = Number(shippingInfo?.fullAddress?.length) > 60

  const getFullAddress = () => {
    if (!shippingInfo?.fullAddress) return '-'

    if (!showMore && isShowMoreButton) {
      return shippingInfo?.fullAddress.substring(0, 60)
    }

    return shippingInfo?.fullAddress
  }

  return (
    <div className="flex w-[55%] flex-col">
      <FieldValue
        classNames={fieldClassNames}
        label="Total Berat Produk"
        value={
          <>
            {shippingInfo?.productWeight}{' '}
            <span
              className="text-caption-sm-semibold cursor-pointer text-secondary500"
              onClick={shippingInfo?.onClickTotalProduct}
            >
              ({shippingInfo?.productQty} produk)
            </span>
          </>
        }
      />
      <FieldValue classNames={fieldClassNames} label="Permintaan Tiba" value={shippingInfo?.arrivalDate} />
      <FieldValue
        classNames={fieldClassNames}
        label="Alamat"
        value={
          <>
            <span className="text-caption-sm-semibold">{shippingInfo?.labelAddress}</span>
            <br />
            <>
              {getFullAddress()}
              {isShowMoreButton && (
                <>
                  <br />
                  <button onClick={() => setShowMore(!showMore)} className="text-caption-sm-semibold text-secondary500">
                    {showMore ? 'Lihat Lebih Sedikit' : 'Lihat Selengkapnya'}
                  </button>
                </>
              )}
            </>
          </>
        }
      />
    </div>
  )
}

const NegoItemInformation = ({
  image,
  header,
  prices: { agreementPrice = null, basePrice, taxes, alertText },
  isWholesale,
  regionName,
  shippingInfo,
}: TNegoItemInformationProps) => {
  const hasShippingInfo = Boolean(shippingInfo)

  return (
    <div className="mb-4 w-full">
      <div className="flex">
        <div className="mr-4 flex h-12 w-12 items-center justify-center">{image}</div>
        <div className="w-full">
          {header}
          <div className="flex w-full justify-between gap-2">
            <div className={cx("flex flex-col", hasShippingInfo ? "w-[45%]" : "w-full")}>
              <FieldValue
                classNames={hasShippingInfo ? { value: 'text-caption-sm-bold' } : productFieldClassName}
                label="Harga Satuan"
                value={
                  <div className="flex flex-wrap gap-2">
                    <div className={cx(hasShippingInfo ? "flex flex-col" : "space-x-2")}>
                      <span>
                      {formatIntoCurrency({
                        value: calculatePriceAfterTax(
                          basePrice,
                          taxes.map((tax) => tax.rate),
                        ),
                      })}
                      </span>
                      {Boolean(alertText) && (
                        <Chips color="warning" size="small" variant="outline">
                          {alertText}
                        </Chips>
                      )}
                      </div>
                      <div className="flex-wrap space-x-1">
                        {isWholesale && (
                          <Chips color="tertiary">
                            Grosir
                          </Chips>
                        )}
                        {regionName && (
                          <Chips color="tertiary">{`Harga ${capitalize(regionName)}`}</Chips>
                        )}
                      </div>
                  </div>
                }
              />
              <FieldValue label="DPP" value={formatIntoCurrency({ value: basePrice })} />
              {taxes.map(({ title, rate }) => {
                if (title === 'PPN' && !rate) {
                  return <FieldValue label={title} value="-" key={title} />
                }

                if (rate > 0) {
                  const taxAmount = Math.floor(basePrice * (rate / 100))
                  return (
                    <FieldValue
                      label={`${title} ${rate}%`}
                      value={formatIntoCurrency({ value: taxAmount })}
                      key={`${title}-${rate}`}
                    />
                  )
                }

                return null
              })}
              <FieldValue
                classNames={hasShippingInfo ? { value: 'text-caption-sm-bold' } : productFieldClassName}
                label="DPP Kesepakatan"
                value={agreementPrice !== null ? formatIntoCurrency({ value: agreementPrice }) : '-'}
              />
              {!hasShippingInfo && (
                <div className="text-caption-sm-italic text-tertiary300">
                  {generateLabelTax({
                    ppnPercentage: taxes.find((tax) => tax.title === 'PPN')?.rate ?? 0,
                    ppnBmPercentage: taxes.find((tax) => tax.title === 'PPnBM')?.rate ?? 0,
                  })}
                </div>
              )}
            </div>
            {hasShippingInfo && <ShippingSection shippingInfo={shippingInfo} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NegoItemInformation
