'use client'

import { forwardRef, useRef } from 'react'
import { cx } from 'class-variance-authority'
import { X } from 'react-feather'
import {
  baseIconStyle,
  inputStyle,
  addonLeftAddonRightStyle,
  wrapperStyle,
  wrapperStyleSize,
  addonLeftStyle,
  TTextFieldProps,
  borderModifierStyle,
  addonRightStyle,
  labelStyle,
  captionStyle,
} from '.'
import useForkRef from 'shared-utils/src/useForkRef'
import TextInputCounter from '../TextInputCounter'

/**
 * Text Fields allow users to enter and edit text content into a UI. They typically appear in forms.
 */
const TextField = forwardRef<HTMLInputElement, TTextFieldProps>(function (props, ref) {
  const {
    variant = 'normal',
    iconLeft: IconLeft,
    iconRight: IconRight,
    addonLeft,
    addonRight,
    disabled = false,
    size = 'medium',
    className,
    wrapperProps,
    fieldType = 'outline',
    label,
    caption,
    clearable,
    captionClassName,
    captionIcon,
    isTelephoneNumber,
    maxLength,
    value,
    addOnRightClassName,
    hideInputCounter = false,
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement>()
  const handleRef = useForkRef(inputRef, ref)

  return (
    <label {...wrapperProps}>
      {label && <div className={labelStyle}>{label}</div>}
      <div
        className={cx(
          wrapperStyle.base,
          fieldType === 'inline' && wrapperStyle.inline,
          wrapperStyleSize[size as keyof typeof wrapperStyleSize],
          Boolean(addonLeft) && 'pl-0',
          Boolean(addonRight) && 'pr-0',
          disabled &&
            '!cursor-not-allowed bg-tertiary25 [&_*]:!text-tertiary200 [&_*]:[-webkit-text-fill-color:theme(colors.tertiary200)]',
          wrapperProps?.className,
        )}
      >
        {addonLeft && (
          <span
            data-element="addon-left"
            className={cx(
              addonLeftAddonRightStyle.base,
              addonLeftStyle.base,
              fieldType === 'outline' && [addonLeftAddonRightStyle.outline, addonLeftStyle.outline],
              fieldType === 'inline' && [addonLeftAddonRightStyle.inline, addonLeftStyle.inline],
            )}
          >
            {addonLeft}
          </span>
        )}

        {IconLeft && <IconLeft className={cx(baseIconStyle, 'mr-4')} />}
        {isTelephoneNumber && (
          <div className="text-caption-sm-semibold flex h-10 w-[37px] -translate-x-4 items-center rounded-l-4 bg-tertiary50 p-2 text-tertiary300">
            +62
          </div>
        )}
        <input
          {...rest}
          ref={handleRef}
          className={cx(inputStyle, className, isTelephoneNumber && '-translate-x-1')}
          disabled={disabled}
          value={value?.toString()}
          maxLength={maxLength}
        />
        <div
          className={cx(
            borderModifierStyle.base,
            fieldType === 'outline' && borderModifierStyle.outline,
            fieldType === 'inline' && borderModifierStyle.inline,
            fieldType === 'no-border' && borderModifierStyle.noBorder,
            fieldType === 'outline-no-border-radius' && borderModifierStyle.outlineNoBorderRadius,
            variant === 'success' && !disabled && '!border-success500',
            variant === 'error' && !disabled && '!border-error500',
          )}
        />
        {clearable && (
          <X
            className={cx(baseIconStyle, 'ml-4 cursor-pointer')}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = ''
                inputRef.current.dispatchEvent(
                  new Event('input', {
                    bubbles: true,
                    cancelable: true,
                  }),
                )
              }
            }}
          />
        )}
        {IconRight && !clearable && <IconRight className={cx(baseIconStyle, 'ml-4')} />}
        {addonRight && (
          <span
            data-element="addon-right"
            className={cx(
              addonLeftAddonRightStyle.base,
              addonRightStyle.base,
              fieldType === 'outline' && [addonLeftAddonRightStyle.outline, addonRightStyle.outline],
              fieldType === 'inline' && [addonLeftAddonRightStyle.inline, addonRightStyle.inline],
              addOnRightClassName,
            )}
          >
            {addonRight}
          </span>
        )}
      </div>
      <div className={cx('flex', caption ? 'justify-between' : 'justify-end')}>
        {caption && (
          <div className={cx(Boolean(captionIcon) && 'flex flex-nowrap items-center')}>
            {!!captionIcon && <div className="mr-1 mt-2 h-3 w-3 shrink-0">{captionIcon}</div>}
            <div
              id={variant === 'error' ? 'error' : undefined}
              className={cx(
                captionClassName,
                captionStyle,
                variant === 'error' && 'text-error500',
                variant === 'success' && 'text-success500',
                variant === 'normal' && 'text-tertiary300',
                Boolean(captionIcon) && 'flex-grow',
              )}
            >
              {caption}
            </div>
          </div>
        )}
        {maxLength && !hideInputCounter && (
          <TextInputCounter
            maxLength={maxLength || 0}
            inputRef={inputRef}
            variant={variant}
            initialLength={(value || rest.defaultValue)?.toString().length ?? 0}
            isInputDisabled={disabled}
          />
        )}
      </div>
    </label>
  )
})

export default TextField
