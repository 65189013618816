export const CDN_URL =
  process.env.NEXT_PUBLIC_CDN_URL ?? "https://asset.inaproc.id/govmart"

export const ZENDESK_URL =
  process.env.NEXT_PUBLIC_ZENDESK_URL ?? "https://bantuan.inaproc.id/hc/id-id"

export const NEXT_PUBLIC_GOOGLE_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_API_KEY ??
  "AIzaSyBzN1T3OcDtShC-hdyuRB0t_39wPTDUphI"

export const ZENDESK_ACCOUNT_URL =
  process.env.NEXT_PUBLIC_ZENDESK_ACCOUNT_URL ??
  "https://bantuan.inaproc.id/hc/id-id"
