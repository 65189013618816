interface TFormatIntoCurrencyProps {
  value: number
  range?: {
    min: number
    max: number
  }
  locale?: string
  currency?: string
  minimumFractionDigits?: number
  withSpacing?: boolean
}

const formatRangePrice = (value: number) => {
  if (value <= 1000000) {
    return value.toLocaleString("id-ID")
  }

  const millionPrice = Math.floor(value / 100000) / 10
  const formattedPrice = millionPrice.toLocaleString("id-ID").replace(".", ",")
  return formattedPrice + "jt"
}

export default function formatIntoCurrency({
  value,
  range,
  locale = "id-ID",
  currency = "IDR",
  minimumFractionDigits = 0,
  withSpacing = true,
}: TFormatIntoCurrencyProps): string {
  if (range) {
    return `Rp ${formatRangePrice(range.min)} - ${formatRangePrice(range.max)}`
  }

  const formated = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(value)

  if (!withSpacing) {
    return formated.replace(/\s/g, "")
  }

  return formated
}

export const formatCurrencyWithSuffix = (number: number) => {
  if (number === 0) {
    return `0`
  }

  const suffixes = ["", "ribu", "juta", "miliar", "triliun"]
  const power = Math.floor(Math.log10(Math.abs(number)) / 3)

  const scaledNumber = number / Math.pow(10, power * 3)
  const roundedNumber = Math.round(scaledNumber * 10) / 10

  return `${roundedNumber} ${suffixes[power]}`
}
