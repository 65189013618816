import { cn } from 'shared-utils'
import { TFieldValueProps } from './FieldValue.types'

const FieldValue = ({ label, value, classNames }: TFieldValueProps): JSX.Element => {
  return (
    <div className={cn('text-caption-sm-regular mb-1 flex', classNames?.container)}>
      <span className={cn('min-w-100 text-caption-sm-regular mr-2 w-17', classNames?.label)}>{label}</span>
      <span className={cn('mr-1 w-fit')}>:</span>
      <span className={cn('text-caption-sm-regular', classNames?.value)}> {value}</span>
    </div>
  )
}

export default FieldValue
