import { cx } from "class-variance-authority"
import { twMerge } from "tailwind-merge"

type ClassValue = Parameters<typeof cx>[0]

/**
 * Combines class names using `class-variance-authority` and `tailwind-merge`.
 *
 * @param inputs - An array of class values to be combined.
 * @returns A merged and optimized string of class names.
 */
export default function cn(...inputs: ClassValue[]) {
  return twMerge(cx(inputs))
}
