type TValidateImageDimensionsProps = {
  file: File
  minWidth: number
  minHeight: number
  maxWidth: number
  maxHeight: number
}

const validateImageDimensions = ({
  file,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
}: TValidateImageDimensionsProps): Promise<void> => {
  return new Promise((resolve, reject) => {
    const imageUrl = URL.createObjectURL(file)
    const image = new Image()

    image.onload = () => {
      if (image.width < minWidth || image.height < minHeight) {
        reject(
          new Error(
            `Dimensi gambar belum sesuai. Minimal ${minWidth}x${minHeight} piksel!`
          )
        )
      } else if (image.width > maxWidth || image.height > maxHeight) {
        reject(
          new Error(
            `Dimensi gambar belum sesuai. Maksimal ${maxWidth}x${maxHeight} piksel!`
          )
        )
      } else {
        resolve()
      }

      URL.revokeObjectURL(imageUrl)
    }

    image.onerror = () => {
      reject(new Error("Gagal memproses gambar."))
    }

    image.src = imageUrl
    image.decode()
  })
}

export default validateImageDimensions
