'use client'
import Link from 'next/link'
import { HTMLProps } from 'react'
import styles from './TabBar.module.css'
import { cx } from 'class-variance-authority'
import { usePathname } from 'next/navigation'
import { isSubpath } from '../../isSubpath'

export interface TabBarProps extends HTMLProps<HTMLElement> {
  equalItemWidth?: boolean
}

export function TabBar({ className, children, equalItemWidth = false, ...rest }: TabBarProps) {
  return (
    <nav className={cx(className, styles.tabBar)} {...rest} data-equal-item-width={equalItemWidth}>
      <ul>{children}</ul>
    </nav>
  )
}

export interface TabBarItemProps {
  title: string
  href?: string
  className?: string
  isActive?: boolean
  onClick?: (e?: React.MouseEvent<HTMLLIElement>) => void
  checkSubPath?: boolean
  children?: React.ReactNode
}

export function TabBarItem({
  onClick,
  title,
  href,
  className,
  isActive,
  checkSubPath = true,
  children,
}: TabBarItemProps) {
  const pathname = usePathname()
  return (
    <li
      className={cx(className, styles.tabBarItem)}
      data-active={checkSubPath ? isActive || (href && isSubpath(href, pathname)) : isActive}
      onClick={onClick}
    >
      {href ? (
        <Link href={href}>
          {title} {children}
        </Link>
      ) : (
        <span>
          {title} {children}
        </span>
      )}
    </li>
  )
}
