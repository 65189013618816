'use client'

import { forwardRef, useMemo, useRef } from 'react'
import { AlertTriangle } from 'react-feather'
import { TextField } from '../index'

import { formatRupiah, useForkRef } from 'shared-utils'
import { TPriceTextField } from './PriceTextField.types'

const PriceTextField = forwardRef<HTMLInputElement, TPriceTextField>(
  ({ label, errorMessage, value, onChange, ...restProps }: TPriceTextField, ref) => {
    const inputRef = useRef<HTMLInputElement>()
    const handleRef = useForkRef(inputRef, ref)

    const valueInput = useMemo(() => {
      if (!value) return ''

      return formatRupiah(value as string).trimStart()
    }, [value])

    return (
      <TextField
        ref={handleRef}
        {...restProps}
        addonLeft="Rp"
        label={label}
        type="text"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        pattern="^[0-9.]+$"
        onChange={(e) => {
          const sanitizedValue = e.currentTarget.value.replaceAll('.', '')
          if (!e.currentTarget.validity.valid) return
          onChange(sanitizedValue)
        }}
        value={valueInput}
        caption={
          errorMessage && (
            <div className="flex items-center gap-1">
              <AlertTriangle width={12} height={12} /> <span>{errorMessage}</span>
            </div>
          )
        }
        variant={errorMessage ? 'error' : 'normal'}
      />
    )
  },
)

export default PriceTextField
