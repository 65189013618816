'use client'

import { ReactNode } from 'react'
import { PaginationProps } from './Pagination.type'
import styles from './Pagination.module.css'
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'react-feather'
import { cx } from 'class-variance-authority'

interface PageButtonProps {
  pageNumber: number
  isActive: boolean
  onClick(): void
}

function PageButton({ pageNumber, isActive, onClick }: PageButtonProps) {
  return (
    <button
      className={styles.button}
      data-active={isActive}
      onClick={() => {
        // no need to refresh for active page
        if (!isActive) {
          onClick()
        }
      }}
    >
      {pageNumber}
    </button>
  )
}

function Ellipsis() {
  return <MoreHorizontal className={styles.more} size={24} />
}

export default function Pagination({
  currentPage: unfilteredCurrentPage,
  numberOfPages,
  onPageChange,
  className,
  ...props
}: PaginationProps) {
  const currentPage = unfilteredCurrentPage <= numberOfPages && unfilteredCurrentPage > 0 ? unfilteredCurrentPage : 1
  const pages: ReactNode[] = []

  let startPage: number
  let stopPage: number

  if (numberOfPages <= 5) {
    startPage = 2
    stopPage = numberOfPages - 1
  } else if (currentPage < 4) {
    startPage = 2
    stopPage = Math.min(5, numberOfPages - 1)
  } else if (currentPage >= numberOfPages - 2) {
    startPage = numberOfPages - 4
    stopPage = numberOfPages - 1
  } else {
    startPage = currentPage - 2
    stopPage = currentPage + 2
  }

  for (let i = startPage; i <= stopPage; i++) {
    pages.push(<PageButton key={i} pageNumber={i} isActive={i === currentPage} onClick={() => onPageChange?.(i)} />)
  }

  return (
    <div className={cx(styles.pagination, className)} {...props}>
      <button className={styles.button} disabled={currentPage === 1} onClick={() => onPageChange?.(currentPage - 1)}>
        <ChevronLeft className={styles.chevron} size={24} />
      </button>

      <PageButton pageNumber={1} isActive={currentPage === 1} onClick={() => onPageChange?.(1)} />

      {startPage > 2 && (
        <button className={cx(styles.button, styles.ellipsisButton)} onClick={() => onPageChange?.(currentPage - 3)}>
          <Ellipsis />
        </button>
      )}

      {pages}

      {stopPage < numberOfPages - 1 && (
        <button className={cx(styles.button, styles.ellipsisButton)} onClick={() => onPageChange?.(currentPage + 3)}>
          <Ellipsis />
        </button>
      )}

      {numberOfPages > 1 && (
        <PageButton
          pageNumber={numberOfPages}
          isActive={currentPage === numberOfPages}
          onClick={() => onPageChange?.(numberOfPages)}
        />
      )}

      <button
        className={styles.button}
        disabled={currentPage === numberOfPages}
        onClick={() => onPageChange?.(currentPage + 1)}
      >
        <ChevronRight className={styles.chevron} size={24} />
      </button>
    </div>
  )
}
