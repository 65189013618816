const formatRupiah = (number: string) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(Number(number)).replace("Rp", "")
}

export default formatRupiah
