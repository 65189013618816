import isValidFilename from "valid-filename"

const isValidFileName = (fileName: string) => {
  const nonAlphanumericPattern = /[^a-zA-Z0-9_\-\s]/
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "")
  return (
    isValidFilename(fileName) &&
    !nonAlphanumericPattern.test(fileNameWithoutExtension)
  )
}

export default isValidFileName
