'use client'

import { PropsWithChildren, useState, type ReactNode } from 'react'
import { Provider, Root, Trigger, Content, Arrow, Portal } from '@radix-ui/react-tooltip'
import { cx } from 'class-variance-authority'

interface ConditionalPortalProps {
  children: ReactNode
  withPortal?: boolean
}

function ConditionalPortal({ children, withPortal }: ConditionalPortalProps) {
  return withPortal ? <Portal>{children}</Portal> : children
}

export interface TooltipProps {
  title: string
  placement?: 'top' | 'left' | 'bottom' | 'right'
  arrow?: boolean
  withPortal?: boolean
  classNames?: {
    content?: string
    arrow?: string
    trigger?: string
  }
  content?: ReactNode
}

export default function Tooltip({
  children,
  placement = 'top',
  title,
  arrow = true,
  classNames,
  withPortal = false,
  content,
}: PropsWithChildren<TooltipProps>) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Provider delayDuration={200}>
      <Root open={isOpen} onOpenChange={setOpen}>
        <Trigger className={cx('cursor-pointer', classNames?.trigger)} asChild>
          {children}
        </Trigger>
        <ConditionalPortal withPortal={withPortal}>
          <Content
            forceMount // Force mounting makes it easier to control transition
            side={placement}
            className={cx(
              'text-caption-lg-regular-respon z-[1000] max-w-sm rounded-4 bg-tertiary500 p-3 text-primary25 opacity-100 transition-opacity data-[state=closed]:hidden',
              classNames?.content,
            )}
          >
            {content ? content : <span dangerouslySetInnerHTML={{ __html: title }} />}
            <Arrow opacity={arrow ? undefined : 0} className={cx(classNames?.arrow)} />
          </Content>
        </ConditionalPortal>
      </Root>
    </Provider>
  )
}
