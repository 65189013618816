'use client'
import { cx } from 'class-variance-authority'
import { TCheckboxProps } from './Checkbox.type'
import { Check } from 'react-feather'
import { forwardRef } from 'react'
import styles from './Checkbox.module.css'

const Checkbox = forwardRef<HTMLInputElement, TCheckboxProps>(function (props, ref) {
  const { disabled = false, checked, className, children, size = 'large', wrapperProps, childrenProps, ...rest } = props

  return (
    <label {...wrapperProps} className={cx(styles.wrapper, wrapperProps?.className)} data-size={size}>
      <input
        {...rest}
        checked={checked}
        ref={ref}
        disabled={disabled}
        type="checkbox"
        className={cx(styles.input, className)}
      />
      <span className={styles.icon}>
        <Check size={size === 'large' ? 20 : 14} />
      </span>
      {children && (
        <span {...childrenProps} className={cx(styles.caption, childrenProps?.className)}>
          {children}
        </span>
      )}
    </label>
  )
})

export default Checkbox
