'use client'
import { cx } from 'class-variance-authority'
import styles from './Divider.module.css'
import { TDividerProps } from './Divider.type'

export default function Divider(props: TDividerProps) {
  const { children, weight = 'thin', variant = 'normal', className, ...rest } = props

  return (
    <span className={cx(styles.divider, className)} data-weight={weight} data-variant={variant} {...rest}>
      {children}
    </span>
  )
}
