const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME
const IS_STAGING = process.env.NEXT_PUBLIC_ENV === "staging"
const IS_PREPRODUCTION = process.env.NEXT_PUBLIC_ENV === "preproduction"
const IS_PRODUCTION = process.env.NEXT_PUBLIC_ENV === "production"

const IS_BUYER = APP_NAME === "buyer"
const IS_SELLER = APP_NAME === "seller"
const IS_INTERNAL = APP_NAME === "internal"
const IS_BLACKLIST = APP_NAME === "blacklist"
const IS_ACCOUNT = APP_NAME === "account"
const IS_VERIFICATOR = APP_NAME === "verificator"

export const CHECK_IS_CANARY = () => {
  let urlCanary = process.env.NEXTAUTH_URL
  if (IS_BUYER) urlCanary = process.env.NEXT_PUBLIC_AUTH_BASE_URL_BUYER
  else if (IS_SELLER) urlCanary = process.env.NEXT_PUBLIC_AUTH_BASE_URL_SELLER
  else if (IS_INTERNAL)
    urlCanary = process.env.NEXT_PUBLIC_AUTH_BASE_URL_INTERNAL
  else if (IS_BLACKLIST) urlCanary = process.env.NEXT_PUBLIC_BLACKLIST_URL
  else if (IS_ACCOUNT) urlCanary = process.env.NEXT_PUBLIC_ACCOUNT_URL
  else if (IS_VERIFICATOR) urlCanary = process.env.NEXT_PUBLIC_VERIFICATOR_URL

  return urlCanary?.includes("canary")
}

const IS_CANARY = CHECK_IS_CANARY()

export const FLAG_DIET_COOKIE =
  (IS_STAGING || IS_PREPRODUCTION || IS_CANARY || IS_PRODUCTION) &&
  (IS_BUYER ||
    IS_SELLER ||
    IS_INTERNAL ||
    IS_BLACKLIST ||
    IS_ACCOUNT ||
    IS_VERIFICATOR)
